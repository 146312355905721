var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-component", {
    attrs: { copyData: _vm.procSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mb-2",
                        attrs: { variant: "primary", show: "" },
                      },
                      [
                        _c("div", { staticClass: "alert-body" }, [
                          _c(
                            "span",
                            [
                              _c("feather-icon", {
                                staticClass: "mr-25",
                                attrs: { icon: "AlertCircleIcon", size: "18" },
                              }),
                              _vm._v(
                                "\n              Data on this page is derived from the\n              "
                              ),
                              _c("strong", [_vm._v("procedure type level")]),
                              _vm._v(
                                "\n              , you can override specific fields by clicking the\n              "
                              ),
                              _c("feather-icon", {
                                staticClass:
                                  "ml-30 mr-30 secondary-text cursor-pointer",
                                attrs: { icon: "EditIcon", size: "16" },
                              }),
                              _vm._v("\n              icon\n            "),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "8", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("input-override", {
                              attrs: {
                                label: "Daily claim limit",
                                overrideData:
                                  _vm.generalSettings.dailyLimitDefault,
                                type: "number",
                              },
                              model: {
                                value: _vm.procSettingsCopy.dailyLimit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopy,
                                    "dailyLimit",
                                    $$v
                                  )
                                },
                                expression: "procSettingsCopy.dailyLimit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("single-select-override", {
                              attrs: {
                                label:
                                  "Modifiers to use when within daily limit",
                                optionsRepo: _vm.modifiersRepo,
                                optionsLabel: "code",
                                overrideData:
                                  _vm.generalSettings
                                    .dailyLimitDefaultModifierCode,
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopy
                                    .dailyLimitDefaultModifierId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopy,
                                    "dailyLimitDefaultModifierId",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopy.dailyLimitDefaultModifierId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("single-select-override", {
                              attrs: {
                                label: "Line type",
                                overrideData:
                                  _vm.generalSettings.dailyLimitDefaultLineType,
                                isOptionsFromRepo: false,
                                options: Object.entries(
                                  _vm.dailyLimitLineType
                                ).map(function (e) {
                                  return {
                                    name: e[1],
                                    id: e[0],
                                    status: "Active",
                                  }
                                }),
                                optionsLabel: "name",
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopy
                                    .dailyLimitDefaultLineType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopy,
                                    "dailyLimitDefaultLineType",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopy.dailyLimitDefaultLineType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.procSettings,
      callback: function ($$v) {
        _vm.procSettings = $$v
      },
      expression: "procSettings",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }