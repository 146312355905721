var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.feeSchedulePricingRepo,
          fields: _vm.fields1,
          "load-params": {
            cptId: _vm.$route.params.id,
            PricingForDate: new Date().toISOString().slice(0, 10),
          },
          "sidebar-data": _vm.fsPrice,
          "copy-data": _vm.fsPriceCopy,
          "sidebar-open": _vm.sidebar,
          "is-data-from-model": false,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.fsPrice = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.fsPrice = $event
          },
          "update:copyData": function ($event) {
            _vm.fsPriceCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.fsPriceCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [_c("div")]
            },
            proxy: true,
          },
          {
            key: "cell(pricingDetails)",
            fn: function (data) {
              return [
                _vm._l(data.value, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.modifier ? item.modifier.code : "NONE") +
                          "\n                    "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
          {
            key: "cell(pricingDetails.facilityPrice)",
            fn: function (data) {
              return [
                _vm._l(data.item.pricingDetails, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                        " +
                          _vm._s("$" + item.facilityPrice) +
                          "\n                    "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
          {
            key: "cell(pricingDetails.nonFacilityPrice)",
            fn: function (data) {
              return [
                _vm._l(data.item.pricingDetails, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                        " +
                          _vm._s("$" + item.nonFacilityPrice) +
                          "\n                    "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }