var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2" },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" General")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("GeneralCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      procedureType: _vm.procType,
                    },
                    on: { save: _vm.save },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Daily Limits")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DailyLimitsCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      generalSettings: _vm.generalSettings,
                      procedureType: _vm.procType,
                    },
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Diagnosis Req.")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DiagnosisReqCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      procedureType: _vm.procType,
                    },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedureType": function ($event) {
                        _vm.procType = $event
                      },
                      "update:procedure-type": function ($event) {
                        _vm.procType = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Modifier’s Req.")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("ModifiersReqCC", {
                    attrs: { procedureSettings: _vm.procSettings },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Place of service")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("PlaceOfServiceCC", {
                    attrs: { procedureSettings: _vm.procSettings },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }