var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-component", {
    attrs: { copyData: _vm.procSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "8", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Required Diagnosis" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Required Diagnosis",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "reqDiagnosisSelect",
                                            attrs: {
                                              repo: _vm.diagnosisRepo,
                                              "include-ids":
                                                _vm.procSettingsCopy
                                                  .requiredDiagnosisSettings,
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              multiple: true,
                                              label: "code",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .requiredDiagnosisSettings,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "requiredDiagnosisSettings",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.requiredDiagnosisSettings",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Allowed Diagnosis" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Allowed Diagnosis",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "allowedDiagnosisSelect",
                                            attrs: {
                                              repo: _vm.diagnosisRepo,
                                              "include-ids":
                                                _vm.procSettingsCopy
                                                  .allowedDiagnosisSettings,
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              multiple: true,
                                              label: "code",
                                              sourceIdField: "diagnosisCodeId",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .allowedDiagnosisSettings,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "allowedDiagnosisSettings",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.allowedDiagnosisSettings",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Disallowed Diagnosis" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Disallowed Diagnosis",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "disallowedDiagnosisSelect",
                                            attrs: {
                                              repo: _vm.diagnosisRepo,
                                              "include-ids":
                                                _vm.procSettingsCopy
                                                  .disallowedDiagnosisSettings,
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              multiple: true,
                                              label: "code",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .disallowedDiagnosisSettings,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "disallowedDiagnosisSettings",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.disallowedDiagnosisSettings",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.procSettings,
      callback: function ($$v) {
        _vm.procSettings = $$v
      },
      expression: "procSettings",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }