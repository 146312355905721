var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0 pb-0" },
        [
          _c(
            "tabs-component",
            {
              attrs: { "base-route": { name: "proc-codes-update" }, pills: "" },
            },
            [
              _c(
                "can",
                { attrs: { I: "read", a: "ClaimRules" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Details")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("DetailsCC", {
                        attrs: { dataSource: _vm.procedureCode },
                        on: { save: _vm.save },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        disabled: !_vm.allowed,
                        to: { name: "proc-codes-update-settings" },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Settings")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("Settings", {
                        attrs: { sourceData: _vm.procedureCode },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { disabled: !_vm.allowed },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Procedure Info")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("ProcedureInfoCC")],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { disabled: !_vm.allowed },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Activity & Notes")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("ActivityAndNotesCC")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }