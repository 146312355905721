




import AddProceduresCodesCC from '/src/class-components/procedures/AddProceduresCodesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    AddProceduresCodesCC
  }
})
export default class AddProceduresCodes extends Vue {}
